import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import Header from "../components/Dashboard/Header/Header"
import './style.css';
import Service from "../components/SoftwareDevelopment/Service/Service"
import Footer from "../components/Dashboard/Footer/Footer"
import OurProcess from "../components/OurProcess/OurProcess"
import ContactUs from "../components/ContactUs/ContactUs"
import LessIcon from '../images/expand_less_black_24dp.svg'

export const query = graphql`
    query SoftwareQuery {
   softwareDevelopment:contentfulSoftwareDevelopment(slug: {eq: "/software-development"}) {
    id
    themeColor
    navbars {
      title
      to
      hRef
    }
     header {
      title
      description {
        description
      }
      actionImage {
         gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE,,  width:50)
      }
      image {
         gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
      }
    }
    service {
      title
      ServiceType {
        title
        serviceImage {
           gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
        }
      }
    }
    designAndDevelop {
      title
      description {
        description
      }
      processType {
        title
        description {
          description
        }
        heroImage {
           gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
        }
      }
    }
     footer {
      logo {
         gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
           gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE)
        }
      }
    }
     footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
}
    `

const WebDesign = ({ location, data: { softwareDevelopment } }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <>
      <SEO title="Home" />
      <Navbar navbar={softwareDevelopment.navbars} themeColor={softwareDevelopment.themeColor} />
      {/* <Header header={softwareDevelopment.header} /> */}
      {/* <CustomHeader header={softwareDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} /> */}
      <Header header={softwareDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      <Service service={softwareDevelopment.service.reduce((p, c) => ({ ...p, ...c }), {})} themeColor={softwareDevelopment.themeColor} />
      <OurProcess process={softwareDevelopment.designAndDevelop} themeColor={softwareDevelopment.themeColor} />
      <ContactUs themeColor={softwareDevelopment.themeColor} />
      <Footer footer={softwareDevelopment.footer} copyright={softwareDevelopment.copyright} />
      {/* <Footer footer={softwareDevelopment.footer} copyright={softwareDevelopment.copyright} footerWaveColor={softwareDevelopment.footerWaveColor} /> */}
      <div className="scroll-button-circle" onClick={scrollHandler} style={{ display: scrollPosition > 1000 ? 'flex' : 'none', background: softwareDevelopment.themeColor }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </>
  )
}

export default WebDesign
