import React from 'react'
import round from '../../images/Layer 2.png'
import round1 from '../../images/Layer 4.png'
import './OurProcess.css'
import Fade from 'react-reveal/Fade'
import { GatsbyImage } from "gatsby-plugin-image"

export default function OurProcess({ process, themeColor, ...props }) {
  return (
    <section className="our-process ">
      <img src={round1} alt="round-image " className=" dynamic-round-image img-fluid d-none d-xl-block" style={{ backgroundColor: themeColor }} />
      <img src={round} alt="round-image " className="dynamic-second-round-image img-fluid d-none d-xl-block" style={{ backgroundColor: themeColor }} />
      <div className="container">
        <div className="d-flex justify-content-center flex-column heading-title">
          <Fade bottom duration={3000}>
            <h2 className="text-center mb-0 main-heading-title">{process.title}</h2>
            <div className="d-flex justify-content-center">
              <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
            </div>
          </Fade>
          <Fade bottom duration={3000}>
            <span className="text-center">{process.description.description}</span>
          </Fade>
        </div>
        {process.processType.map((p, i) => {
          return (
            <>
              {i % 2 ?
                <div className="row our-process-inner-container">
                  <Fade left duration={500}>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  d-md-none d-lg-none d-xl-none">
                      {/*<img src={p.heroImage.fluid.src} alt="process-img" className="img-fluid" />*/}
                    </div>
                  </Fade>
                  <Fade left duration={500}>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                      <h4 className="mb-2 our-process-sub-title">{p.heading}</h4>
                      <h3 className="mb-2 our-process-title">{p.title}</h3>
                      <span className="our-process-description">{p.description.description}</span>
                    </div>
                  </Fade>
                  <Fade right duration={500}>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block d-lg-block d-xl-block">
                      {/*<img src={p.heroImage.fluid.src} alt="process-img" className="img-fluid" />*/}
                    </div>
                  </Fade>
                </div>
                :
                <div className="row our-process-inner-container">
                  <Fade left duration={500}>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      {/* <img src={p.heroImage.fluid.src} alt="process-img" className="img-fluid" /> */}
                      <GatsbyImage image={p.heroImage.gatsbyImageData} alt="image" />
                    </div>
                  </Fade>
                  <Fade right duration={500}>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                      <h4 className="mb-2 our-process-sub-title">{p.heading}</h4>
                      <h3 className="mb-2 our-process-title">{p.title}</h3>
                      <span className="our-process-description">{p.description.description}</span>
                    </div>
                  </Fade>
                </div>
              }
            </>
          )
        })}
      </div>
    </section>
  )
}
