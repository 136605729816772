import React, { useState } from 'react'
import './Service.css'
import img1 from '../../../images/Intersection 7.png'
import dots from '../../../images/Group 383.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Rotate from 'react-reveal/Rotate'
import Zoom from 'react-reveal/Zoom'
import Bounce from 'react-reveal/Bounce'
import Slide from 'react-reveal/Slide'

export default function Service({ service, themeColor, ...props }) {
  return (
    <section className="software-development">
      {/* <img src={dots} alt="dots" className="dots-img img-fluid d-none d-lg-block d-xl-block" />
      <img src={dots} alt="dots" className="dots-img-second img-fluid d-none d-lg-block d-xl-block" /> */}
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <Fade bottom duration={2000}>
          <h2 className="mb-0 main-heading-title text-center">{service.title}</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        <div className="row software-development-container">
          {service.ServiceType.map((p, i) => (
            <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center align-items-center">
              <Zoom delay={i * 250}>
                <div className="software-square d-flex align-items-center">
                  <Rotate duration={2000} delay={i * 250}>
                    {/*<img src={p.serviceImage.fluid.src} className="mb-0 software-img mr-3" alt="icon" />*/}
                  </Rotate>
                  <Zoom duration={2000} delay={i * 250}>
                    <h3 className="mb-0 software-development-title">{p.title}</h3>
                  </Zoom>
                </div>
              </Zoom>
            </div>
          ))}
        </div>
      </div>
    </section >
  )
}
